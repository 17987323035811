.Risco{
   color: #c92f2f;
}

.Moderado{
    color: #f88735;
}

.Ok{
    color: #f3d320;
}

.Bom{
    color: #38b42d ;
}

.score{
    margin-left: 5px;
}
.NA {
    color: #000;
    font-weight: 500;
}