h1,h2,h3,h4,h5,h6{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: normal;
}

p{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

label{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    display: inline-block;
    margin: 3px;
}

form{
    display: flex;
    flex-direction: column;
    align-items: center;
}

form input{
    margin-bottom: 20px;
    margin-top: 0px;
    height: 45px;  
    width: 330px;
    padding: 0 15px;
    /* background: #FFFFFF;
    border-radius: 10px;
    border-style: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 13px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
}


textarea{
    margin-bottom: 20px;
    margin-top: 0px;
    height: 100px;  
    width: 330px;
    padding: 10 15px;
    font-size: 13px;
    resize: none;
    /* background: #FFFFFF;
    border-radius: 10px;
    border-style: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;  
}


button:hover{
    cursor: pointer;
}

select{  
    margin-bottom: 20px;
    margin-top: 0px;
    height: 45px;  
    width: 330px;
    padding: 0 15px;
    font-size: 13px;
    background: #FFFFFF;
    border-radius: 10px;
    border-style: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;  
}


ul{
    margin: 0px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

ol{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; 
    font-size: small;
    padding-left: 0;
    margin-bottom: 2px;
}


.rotateimg180 {
    -webkit-transform:rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.submitForm{
    color: #FFFFFF;
    background-color: #E6AC86; 
    border-radius: 10px;
    margin: 15px;
    width: 50%;
    border: none;
}

.botaoTransparente{
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: none;
}

.botaoSubmit{
    color: #FFFFFF;
    background-color: #E6AC86 !important;
    border-radius: 10px;
    text-align: center;
    margin: 15px;
    width: 200px;
    padding: 15px;
    border: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;  
}

.inline{
    display: flex;
    justify-content: center;
    gap: 25px;
}

.inline img{
    border-radius: 5px;
}


input, button, textarea{
    outline: none;
}

input[button], input[submit], button{
    cursor: pointer !important;
}

.subcategorias{
    display: flex;
    max-width: 280px;
    flex-wrap: wrap;
}

.subcategoria{
    display: flex;
    background-color: #E0E0E0;
    padding: 2px 3px 2px 5px;
    border-radius: 50px;
    margin: 0 5px 5px;
    
}

.subcategoria img{
    width: 15px;
    height: 15px;
    margin-left: 8px;
    cursor: pointer;
}

.addBtn{
    color:#E6AC86;  
    cursor:pointer;
}

#divEditarCategoria{
    display: flex;
    flex-direction:column;
    justify-content: space-between;
    align-items:center;
    gap:15px;
    margin-bottom: 30px;
    height: 100%;
}

#divEditarCategoria button{
    display: block;
    white-space: nowrap;
    margin: 0;
    width: 100%;
    height: 20px;
    
}

#subcategorias{
    display: flex;
    flex-direction: column;
}

#subcategorias span{
    display: flex;
    justify-content: flex-start;
    width: 250px;
}

#subcategorias .subcategorias{
    margin-left: 20px;
}

#divAlterarImagem{
    display:flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.enviar{
    color: #FFFFFF;
    background-color: #E6AC86 !important;
    border-radius: 10px;
    text-align: center;
    display: flex !important;
    align-items: center;
    cursor: pointer;
    justify-content:center;
    margin: 15px;
    width: 80% !important;
    max-height: 40px;
    padding: 20px;
    font-size: 1em;
    font-weight: 500;
    border: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;  
}

#divBotoes{
    display: flex;
}

.confirmar{
    margin-top:10px;
}

.div-perguntas{
    display: flex;
    justify-content: space-between;
}

.input-trending{
    width: 300px;
}

form input[type=checkbox]{
    width: 15px;
    margin: 0 10px;
    background-color: green;
    border: 1px solid #fff;
}


.div-verde {
    display:flex;
    align-items: center;
}