.modal{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(0, 0,0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center !important;
    overflow: hidden;
    pointer-events: auto;
}

.modal ::-webkit-scrollbar{
    width: 8px;
    background-color: transparent;
    border-radius: 10px; 
}

.modal ::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background-color: transparent;
}

.containerAdicionar{
    background-color: #ececec;
    min-width: 500px;
    height: 90%;
    color: #000;
    border-radius: 20px;
    display: flex;
    flex-direction:column;
    overflow-y: scroll;
    align-items: center;
}

.containerAdicionarSelo{
    background-color: #ececec;
    width: 500px;
    height: 70%;
    color: #000;
    border-radius: 20px;
    display: flex;
    flex-direction:column;
    overflow-y: scroll;
    align-items: center;
}


.containerAvisoDeletar{
    background-color: #ececec;
    width: 40%;
    height: 45%;
    color: #000;
    border-radius: 20px;
    display: flex !important;
    text-align: center;
    flex-direction:column;
    align-items: center !important;
    overflow-y: scroll;
}
.containerEditarCategoria{
    background-color: #ececec;
    width: 500px;
    height: 80%; 
    align-self: center;
    color: #000;
    border-radius: 20px;
    display: flex;
    text-align: center;
    flex-direction:column;
    align-items: center;
    overflow-y: scroll;
}


 


#deletar{
    border-radius:4px;
    background-color: #E6AC86;
    width:30%;
    text-align: center;
    height:30px;
    margin-top:50px;
    display: flex;
    justify-content: center;
    align-items: center;


}

#botoesDeletar{
    width:80%;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    
}

#textoDeletar{
    margin-top:-30px;
}


.containerAviso{
    background-color: #fff;
    width: 40%;
    height: 45%;
    color: #000;
    border-radius: 20px;
    display: flex;
    flex-direction:column;
    align-items: center;
    overflow-y: scroll;
}


.close{
    background-color: transparent;
    outline: none;
    width: 40px;
    height: 40px;
    border: none;
    display:flex;
    align-items: center;
    margin-left: auto;
    padding-right: 30px;
    margin-top: 10px;
}

.close img{
    width: 15px;
    height: 15px;
    position: right;
}

.content{
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    margin:auto;
}